'use client';
import { useParams } from 'next/navigation';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../i18n-config';

// Define the Link interface
export interface ILink {
  href: string;
  name?: string; // Optional for links without display names
  target?: string; // Optional for external links or links needing target="_blank"
}

// Define the Links structure
export interface ILinks {
  home: ILink;
  pricing: ILink;
  humanClonePage: ILink;
  videoChatbot: ILink;
  external: ILink;
  termsOfService: ILink;
  faq: ILink;
  contactUs: ILink;
  partnership: ILink;
  login: ILink;
  register: ILink;
  Roadmap: ILink;
  AIChatbot: ILink;
  ScenarioChatbot: ILink;
  VideoBot: ILink;
  HumanClone: ILink;
  WhyVideoBot: ILink;
  TextVSVideo: ILink;
  ChatbotIntegration: ILink;
  BecomeAPartner: ILink;
  BecomeAnAffiliate: ILink;
}

export default function useLinks() {
  const params = useParams<{ lang?: string }>();

  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');

  const data = useMemo(() => {
    return {
      home: {
        href: `/${lang}`,
        name: t('SiteNavbar/l-05'),
      },
      pricing: {
        href: `/${lang}/plans`,
        name: t('home/SiteSocialNetwork/link-02'),
      },
      humanClonePage: {
        href: `/${lang}/human-clone-page`,
        name: t('SiteNavbar/l-06'),
      },
      videoChatbot: {
        href: `/${lang}/video-chatbot`,
        name: t('SiteNavbar/l-07'),
      },
      external: {
        href: 'https://l-k.io/tPeKRL',
        name: t('home/SiteSocialNetwork/link-06'),
        target: '_blank',
      },
      termsOfService: {
        href: `/${lang}/terms-of-service`,
        name: t('home/SiteSocialNetwork/link-03'),
        target: '_blank',
      },
      faq: {
        href: `/${lang}/frequently-asked-questions`,
        name: t('home/SiteSocialNetwork/link-05'),
        target: '_blank',
      },
      contactUs: {
        href: `/${lang}/contact-us`,
        name: t('home/SiteSocialNetwork/link-04'),
      },
      partnership: {
        href: `/${lang}/partner-programs`,
      },
      login: {
        href: `${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${
          (lang as string).startsWith('en') ? 'en-US' : 'fr-FR'
        }/login`,
        name: t('SiteNavbar/l-01'),
      },
      register: {
        href: `${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${
          (lang as string).startsWith('en') ? 'en-US' : 'fr-FR'
        }/register`,
        name: t('SiteNavbar/l-04'),
      },
      Roadmap: {
        href: 'https://l-k.io/tPeKRL',
        name: t('home/SiteSocialNetwork/link-06'),
        target: '_blank',
      },
      AIChatbot: {
        href: `/${lang}/ai-chatbot`,
        name: t('SiteNavbar/l-09'),
      },
      HumanClone: {
        href: `/${lang}/human-clone-page`,
        name: t('SiteNavbar/l-06'),
      },
      ScenarioChatbot: {
        href: `/${lang}/text-chatbot`,
        name: t('SiteNavbar/l-08'),
      },
      VideoBot: {
        href: `/${lang}/video-chatbot`,
        name: t('SiteNavbar/l-07'),
      },
      BecomeAnAffiliate: {
        name: t('AffiliationPrograms/becomeAffiliate'),
        href: `/${lang}/affiliation-programs`,
      },
      BecomeAPartner: {
        name: t('PartnerPrograms/becomeAPartner'),
        href: `/${lang}/partner-programs`,
      },
      ChatbotIntegration: {
        name: t('IntegrationsPage/title'),
        href: `/${lang}/chatbot-integration`,
      },
      TextVSVideo: {
        name: t('CompareTextAndVideo/t-01'),
        href: `/${lang}/compare-chatbot-video`,
      },
      WhyVideoBot: {
        href: `/${lang}/advantages-of-the-video-chatbot`,
        name: t('CompareTextAndVideo/t-02'),
      },
    };
  }, [lang, t]);

  const [Links, setLinks] = useState<ILinks>(data);

  useEffect(() => {
    setLinks(data);
  }, [data]);

  return { Links };
}
